/* #toast-container {
    top: auto !important;
    right: auto !important;
    bottom: 0%;
    transform: translateY(-50%);
    transform: translateX(-4%);
    margin:7% !important;
  } */

  #toast-container {
    top: auto !important;
    right: 0 !important;
    bottom: 0;
    transform: translateY(-50%);
    margin:0 !important;
  }
  .toast {
    max-width: 100%!important;
  } 

  .danger_btn{
    width: 80%;
    border: 5px solid red;
    padding: 8px;
    margin-left: 10%;
    padding-bottom: 26px;
    margin-bottom:20px;
  }

  .p1{
    background-color:black !important;
  }

  textarea{
    overflow: auto;
    resize: none;
    width: 100%;
    height: 100% !important;
    font-size: large;
    background-color: wheat;
  }

  .t1{
    padding:7px;
    font-size:x-large;
    width: 70%;
    font-weight: 500;
    float: right;
    clear: right;
    background-color: white;
  }
